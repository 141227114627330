// src/assets/images/comicPages/pagesData.js
const pages = [
  {
    id: 1,
    src: require('./page1.webp'),
    alt: 'Comic Page 1',
    text: { type: 'text', content: 'Limited Edition' },
    overview: {
      headline: 'The Adventure Begins',
      body: 'Dive into the saga of exclusivity with our Limited Edition Collection. Each curated piece in this collection is a tribute to the legends of style and heroism. With the clock ticking, heroes have just 24 hours to secure their spoils before they slip back into myth. Will you rise to the occasion and claim your exclusive gear?',
      callToAction: 'Carpe Diem - Explore the Collection',
      postScript: 'Embark on your quest, and let your legacy be legendary with Uncle Donny T.',
    },
    panels: [
      { id: '1-1', type: 'header', content: 'Rare Finds: Justice Collection' },
      { id: '1-2', type: 'text', content: 'Welcome to Uncle Donny T!' },
      { id: '1-3', type: 'image', content: require('./panelContent/page1/panel1.webp') },
    ],
  },
  {
    id: 2,
    src: require('./page2.webp'),
    alt: 'Comic Page 2',
    text: { type: 'text', content: 'Products' },
    overview: {
      headline: 'Super Market - Gear Up with Style!',
      body: 'Step into the Super Market, where the attire is as heroic as your spirit. Discover apparel forged for comfort and designed to inspire. From the vibrant threads of our t-shirts to the bold statements of our hats and beanies, there’s something here for every champion of fashion.',
      callToAction: 'Don Your Armor - Browse the Market',
      postScript: 'Suit up with the finest garb in all of Metro - where every piece tells a part of your story.',
    },
    panels: [
      { id: '2-1', type: 'header', content: 'Super Market: Gear Up!' },
      { id: '2-2', type: 'text', content: 'Our heroes face their first challenge.' },
      { id: '2-3', type: 'image', content: require('./panelContent/page2/panel1.webp') },
    ],
  },
  {
    id: 3,
    src: require('./page3.webp'),
    alt: 'Comic Page 3',
    text: { type: 'text', content: 'Media' },
    overview: {
      headline: 'Donny T.V. - Not Fake News!',
      body: 'Stay updated with the latest adventures of Uncle Donny T through our vibrant Media Hub. Dive into exhilarating blog posts, watch epic battles unfold in videos, and scroll through a gallery of triumphs. Connect with the saga on all our media channels and become a part of the legend.',
      callToAction: 'Assemble with Us - Join the Revolution',
      postScript: 'Follow Uncle Donny T on his quest for justice and style. Be the first to know, straight from Metro’s most trusted media source.',
    },
    panels: [
      { id: '3-1', type: 'header', content: 'NFN: Not Fake News' },
      { id: '3-2', type: 'text', content: 'A mysterious figure appears.' },
      { id: '3-3', type: 'image', content: require('./panelContent/page3/panel1.webp') },
    ],
  },
  {
    id: 4,
    src: require('./page4.webp'),
    alt: 'Comic Page 4',
    text: { type: 'text', content: 'Origin Story' },
    overview: {
      headline: 'The Dawn of Uncle Donny T!',
      body: 'Discover the origins of the Metro guardian, Uncle Donny T. From the early whispers of his legend to the full blaze of his glory. Our Genesis Chronicles give you an all-access pass to the pivotal moments that shaped the hero we revere today. Witness the journey from man to icon, from fashion aficionado to savior of style.',
      callToAction: 'Discover the Legacy - Read our Chronicles',
      postScript: 'Every hero has a beginning. Explore the epic saga behind the suit and cape, and be inspired by the ascent of Uncle Donny T.',
    },
    panels: [
      { id: '4-1', type: 'header', content: 'Genesis Chronicles: Legends Rise' },
      { id: '4-2', type: 'text', content: 'The plot thickens as the journey continues.' },
      { id: '4-3', type: 'image', content: require('./panelContent/page4/panel1.webp') },
    ],
  },
  {
    id: 5,
    src: require('./page5.webp'),
    alt: 'Comic Page 5',
    text: { type: 'text', content: 'Citizens Of Metro' },
    overview: {
      headline: 'Metro Pulse - The Citizens Speak!',
      body: 'Hear the tales of transformation and triumph straight from the citizens of Metro. Our Metro Voices section is where patrons don their capes and share their experiences with Uncle Donny T’s legendary collection. Read through the chronicles of satisfaction, style, and super-heroic service experienced by our valued allies.',
      callToAction: 'Echoes of Triumph - Unveil the Truth',
      postScript: 'From the bustling streets of Metro City, the voices rise in unison—praising the extraordinary journey with Uncle Donny T.',
    },
    panels: [
      { id: '5-1', type: 'header', content: 'Metro Voices: The City Perspective' },
      { id: '5-2', type: 'text', content: 'An unexpected ally joins the group.' },
      { id: '5-3', type: 'image', content: require('./panelContent/page5/panel1.png') },
    ],
  },
  {
    id: 6,
    src: require('./page6.webp'),
    alt: 'Comic Page 6',
    text: { type: 'text', content: 'The Donny Signal' },
    overview: {
      headline: 'Beacon of Inquiry - Send Up Your Signal',
      body: 'In need of assistance? Have queries or quips? Light up the sky with the Donny Signal, and well be there! Uncle Donny Ts headquarters is always ready to respond to the call of our fellow citizens. Reach out to us with your questions, concerns, or even your tales of valor. Our team is on standby to aid you in your heroic endeavors.',
      callToAction: 'Summon Us - Contact Uncle Donny T',
      postScript: 'Whether youre facing a fashion dilemma or require guidance through our collection, Uncle Donny Ts support team is your ally in the quest for answers.',
    },
    panels: [
      { id: '6-1', type: 'header', content: 'Beacon of Inquiry: Summon Us' },
      { id: '6-2', type: 'text', content: 'The journey reaches a thrilling climax.' },
    ],
    conditionalComponent: 'ContactForm',
  },
];

export default pages;
