// src/components/ComicBookGrid.js
import React, { useState } from 'react';
import pages from '../assets/images/comicPages/pagesData';
import '../assets/css/ComicBookGrid.css';
import '../assets/styles/styles.scss';
import NavBar from './NavPanel';
import CustomButton from './ButtonStyles';
import ContactUsPage from './ContactUsPage';

// Layout component for the grids
function Grid({ children, className, ...rest }) {
  return (
    <div className={`nav-container ${className}`} {...rest}>
      {children}
    </div>
  );
}

function ComicBookGrid() {
  const [currentPageIndex, setCurrentPageIndex] = useState(null);
  const [selectedPanelIndex, setSelectedPanelIndex] = useState(null);
  const [showContactPage, setShowContactPage] = useState(false);

  const resetPanelView = () => {
    setSelectedPanelIndex(null);
    setCurrentPageIndex(null);
  };

  const renderNavBar = () => (
    <NavBar
      setCurrentPageIndex={setCurrentPageIndex}
      resetView={resetPanelView}
    />
  );

  const renderPageGrid = () => (
    <Grid className="nav-container">
      {pages.map((page, index) => (
        <div key={page.id} className="grid-item" onClick={() => setCurrentPageIndex(index)}>
          {page.text && page.text.type === 'text' && (
            <div className="grid-container-header">
              <div className="grid-item">
                <section className="panel blue">
                  <div className="comic-image">
                    <div className="burst">
                      <h4 className="big">{page.text.content}</h4>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          )}
          <img src={page.src} alt={page.alt} className="grid-image" />
          {page.overview && (
            <div className="page-overview-content">
              <h2 className="big">{page.overview.headline}</h2>
              <p className="page-overview-body">{page.overview.body}</p>
              <CustomButton
                text={page.overview.callToAction}
                onClick={() => { setCurrentPageIndex(index); }}
              />
              <p className="page-overview-postscript">{page.overview.postScript}</p>
            </div>
          )}
        </div>
      ))}
    </Grid>
  );

  const renderPanelGrid = () => {
    if (currentPageIndex !== null) {
      const page = pages[currentPageIndex];

      return (
        <Grid>
          {/* Render the selected page's overview and image, similar to renderPageGrid */}
          <div key={page.id} className="grid-item">
            {page.text && page.text.type === 'text' && (
              <div className="grid-container-header">
                <div className="grid-item">
                  <section className="panel blue">
                    <div className="comic-image">
                      <div className="burst">
                        <h4 className="big">{page.text.content}</h4>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            )}
            <img src={page.src} alt={page.alt} className="grid-image" />
            {page.overview && (
              <div className="page-overview-content">
                <h2 className="big">{page.overview.headline}</h2>
                <p className="page-overview-body">{page.overview.body}</p>
                <CustomButton text={page.overview.callToAction} onClick={() => setCurrentPageIndex(currentPageIndex)} />
                <p className="page-overview-postscript">{page.overview.postScript}</p>
              </div>
            )}
          </div>

          {/* Render panels for the selected page */}
          {page.panels.map((panel) => (
            <div key={panel.id} className="grid-item" onClick={() => setSelectedPanelIndex(panel.id)}>
              {panel.type === 'image' && (
                <img src={panel.content} alt={`Panel ${panel.id}`} className="grid-image" />
              )}
              {panel.type === 'header' && (
                <h2 className="evenboxinner">{panel.content}</h2>
              )}
              {panel.type === 'text' && (
                <div className="grid-container-panel-header">
                  <div className="comic-panel">
                    <div className="text">
                      <div className="box box2">
                        <p>{panel.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {page.conditionalComponent === 'ContactForm' && <ContactUsPage setPageIndex={setCurrentPageIndex} />}
        </Grid>
      );
    }
    return renderPageGrid();
  };

  // Decide what to render based on the current state
  return (
    <div>
      {renderNavBar()}
      {showContactPage ? (
        <ContactUsPage setPageIndex={setShowContactPage} />
      ) : selectedPanelIndex !== null ? (
        // If a panel is selected, show the full panel details
        renderPanelGrid()
      ) : currentPageIndex !== null ? (
        // If a page is selected but no panel is selected, show the panel grid
        renderPanelGrid()
      ) : (
        // If nothing is selected, show both the text grid and the image grid
        <>
          {renderPageGrid()}
        </>
      )}
    </div>
  );
}

export default ComicBookGrid;
