// src/components/ContactUsPage.js
import React, { useState } from 'react';
import '../assets/css/ComicBookGrid.css';
import '../assets/styles/styles.scss';
import CustomButton from './ButtonStyles';

function ContactUsPage({ setPageIndex }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/submitContact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Thank you for reaching out! We'll get back to you soon.");
        setFormData({
          name: '',
          email: '',
          message: '',
        });
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Failed to submit contact form: ', error);
      alert('An error occurred while submitting your form. Please try again.');
    }
  };

  const handleBack = () => {
    setPageIndex(null);
  };

  return (
    <div className="p-6 mx-auto bg-blue-300 border-4 border-gray-900 rounded-2xl shadow-custom flex flex-col space-y-4" style={{ maxWidth: 'calc(100% - 1.5rem)' }}>
      <form className="w-full flex flex-col space-y-4" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="block text-sm font-bold text-black">Name:</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-950 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <div>
          <label htmlFor="email" className="block text-sm font-bold text-black">Email:</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-950 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
        </div>
        <div>
          <label htmlFor="message" className="block text-sm font-bold text-black">Message:</label>
          {/* eslint-disable-next-line react/self-closing-comp */}
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} rows="4" required className="mt-1 block w-full px-3 py-2 bg-white border border-gray-950 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"></textarea>
        </div>
      </form>
      <CustomButton text="Submit" onClick={handleSubmit} type="submit" />
      <CustomButton text="Back to the Grid" onClick={handleBack} type="button" />
    </div>
  );
}

export default ContactUsPage;
