import React, { useState, useEffect } from 'react';
import { ReactComponent as YourLogo } from '../assets/images/logo.svg';
import '../assets/css/NavPanel.css';
import CustomButton from './ButtonStyles';
import '../assets/styles/styles.scss';
import pages from '../assets/images/comicPages/pagesData';

function NavBar({ setCurrentPageIndex, resetView }) {
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const navigateToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isPanelOpen) {
        setIsPanelOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPanelOpen]);

  return (
    <div className="comic-image-nav">
      <YourLogo className={`logo ${isPanelOpen ? 'active' : ''}`} onClick={togglePanel} />
      {isPanelOpen && (
      <div className={`nav-panel ${isPanelOpen ? 'active' : ''}`}>
        <CustomButton text="Home" onClick={resetView} />
        {pages.map((page, index) => (
          <CustomButton
            key={page.id}
            text={page.text.content}
            onClick={() => navigateToPage(index)}
          />
        ))}
      </div>
      )}
      <div className="page-overview-content">
        <h1 className="big">Uncle Donny T</h1>
      </div>
    </div>
  );
}

export default NavBar;
