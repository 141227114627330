// src/components/ButtonStyles.js
import React from 'react';
import '../assets/styles/styles.scss';

function CustomButton({ text, onClick }) {
  const buttonTextCharacters = text.split('');
  const characterCountWithoutWhitespaces = buttonTextCharacters.filter((character) => !/\s/.test(character)).length;

  return (
    <button className="button" type="button" onClick={onClick}>
      <span className="button-outline">
        <span className="button-shadow">
          <span className="button-inside">
            <span className="button-text visually-hidden">{text}</span>
            <span className="button-text-characters-container" aria-hidden="true">
              {buttonTextCharacters.map((character, index) => {
                // Creating a unique key for each character
                const key = `${character}-${index}`;
                return !/\s/.test(character) ? (
                  <span
                    key={key}
                    className="button-text-character"
                    style={{ '--delay': `calc(2s / ${characterCountWithoutWhitespaces} * ${index + 1} + 1s)` }}
                  >
                    {character}
                  </span>
                ) : (
                  // For whitespace, we're using the same key approach.
                  // This maintains consistency but ensures unique keys even for whitespace.
                  <span key={key} className="whitespace">{character}</span>
                );
              })}
            </span>
          </span>
        </span>
      </span>
    </button>
  );
}

export default CustomButton;
